import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './pages/LoginPage';
import HelpPage from './pages/HelpPage';
import DevicePickerPage from './pages/DevicePickerPage/DevicePickerPage';
import ErrorPage from './pages/ErrorPage';
import PageNotFound from './pages/PageNotFound';

const Routes = () => (
  <Switch>
    <Route path="/login" exact>
      <Login />
    </Route>
    <Route path="/help" exact>
      <HelpPage />
    </Route>
    <Route path="/device-picker" exact>
      <div>
        <DevicePickerPage />
      </div>
    </Route>
    <Route path="/redirect-success" exact>
      <h1>SUCCESS</h1>
    </Route>
    <Route path="/error" exact>
      <ErrorPage />
    </Route>
    <Route>
      <PageNotFound />
    </Route>
  </Switch>
);

export default Routes;
