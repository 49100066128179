/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { colorRoles, Icon, IconID, spacing } from '@kaluza-tech/component-library';
import { PageTitle, SubTitle, Text, ExternalLink, InternalLink } from '../components/text';
import { PageContainer } from '../components/layout';

const HelpPage = () => {
  return (
    <PageContainer>
      <div
        css={css`
          display: flex;
          height: ${spacing(6)}px;
          align-items: center;
          justify-content: space-between;
          padding: ${spacing(0, 1)};
        `}
      >
        <InternalLink
          to={{ pathname: '/login', search: window.location.search }}
          css={css`
            width: ${spacing(6)}px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Icon iconId={IconID.left} color={colorRoles.textSecondary} width={18} height={18} />
        </InternalLink>
        <PageTitle>Help</PageTitle>
        <div
          css={css`
            width: ${spacing(6)}px;
          `}
        />
      </div>
      <div
        css={css`
          padding: ${spacing(0, 3)};
        `}
      >
        <SubTitle>I don’t remember my email</SubTitle>
        <Text>
          If you don’t remember the email you used to register your smart charger, please{' '}
          <ExternalLink href="mailto:smarthome@ovoenergy.com">contact support</ExternalLink>.
        </Text>
        <SubTitle>I've forgotten my password</SubTitle>
        <Text>You can reset your password from the login screen of your EV charger app.</Text>
        <SubTitle>My email address isn't recognised</SubTitle>
        <Text>
          Some customers use different email addresses for their energy and EV charger accounts.
          Please double-check you're using the right email address for your smart charger. If you're
          still having issues, please{' '}
          <ExternalLink href="mailto:smarthome@ovoenergy.com">contact support</ExternalLink>.
        </Text>
      </div>
    </PageContainer>
  );
};

export default HelpPage;
