/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useLayoutEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import {
  SizeVariation,
  kaluzaColors,
  ToggleButton,
  ToggleButtonGroup
} from '@kaluza-tech/component-library';
import { PageTitle, InternalLink, Text } from '../../components/text';
import Chargers from './Chargers';
import Vehicles from './Vehicles';
import * as api from './api';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

const DevicePickerPage = () => {
  const [deviceType, setDeviceType] = useState('chargers');
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { redirect_uri, client_id, response_type } = qs.parse(window.location.search);

  useLayoutEffect(() => {
    setIsLoading(true);
    api
      .checkAuth0Credentials({
        redirectUrl: redirect_uri,
        clientId: client_id,
        responseType: response_type
      })
      .catch(() => {
        history.push(`/error${window.location.search}`);
      })
      .finally(() => setIsLoading(false));
  }, [redirect_uri, client_id, response_type, history]);

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newDeviceType: string) => {
    if (!!newDeviceType) {
      setDeviceType(newDeviceType);
    }
  };
  if (isLoading) {
    return null;
  }
  return (
    <div
      css={css`
        background-color: white;
        height: 100vh;
        @media (max-width: 724px) {
          height: 100%;
        }
      `}
    >
      <PageTitle
        css={css`
          padding-top: 5%;
          font-size: ${SizeVariation.x08};
          text-align: center;
          color: ${kaluzaColors.night};
        `}
      >
        Select your device
      </PageTitle>
      <div
        css={css`
          text-align: center;
          margin: 40px;
        `}
      >
        <ToggleButtonGroup
          value={deviceType}
          exclusive
          onChange={handleToggle}
          aria-label="device-type"
        >
          <ToggleButton value="chargers" aria-label="left aligned" color={kaluzaColors.fig}>
            <Text>Chargers</Text>
          </ToggleButton>
          <ToggleButton value="vehicles" aria-label="centered">
            <Text>Electric Vehicles</Text>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {deviceType === 'chargers' && <Chargers />}
      {deviceType === 'vehicles' && <Vehicles />}
      <div
        css={css`
          text-align: center;
          margin-top: 40px;
        `}
      >
        <InternalLink to={{ pathname: 'https://forms.gle/yJHmkjbLiUxeH6kV6' }} target="_blank">
          I need help
        </InternalLink>
      </div>
    </div>
  );
};

export default DevicePickerPage;
