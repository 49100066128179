import { energySuppliers, tariffTypes } from './constants';

interface ClientCredentials {
  redirectUrl: string | (string | null)[] | null;
  clientId: string | (string | null)[] | null;
}

interface LoginCredentials extends ClientCredentials {
  username: string;
  password: string;
}

type GraphQLBody = {
  query: string;
  variables?: Record<string, unknown>;
};

export const checkCredentials = async (credentials: ClientCredentials) => {
  const identityURL = process.env['REACT_APP_IDENTITY_URL'];
  const response = await fetch(`${identityURL}/login-oauth/check-credentials`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(credentials)
  });

  if (response.status !== 200) {
    throw Error(response.statusText);
  }
};

const updateCustomerTariffMutation = `
mutation updateCustomerTariff(
      $startDate: String,
      $energySupplier: String!,
      $tariffType: String,
      $periods: [InputTariffData]
    ) {
      updateCustomerTariff(
        startDate: $startDate,
        energySupplier: $energySupplier,
        tariffType: $tariffType,
        periods: $periods
      )  {
    id
  }
}
`;

const graphQlPost = async (token: string, body: GraphQLBody) => {
  try {
    const maestroUrl = process.env['REACT_APP_MAESTRO_URL'];
    const response = await fetch(`${maestroUrl}/query/`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-client-id': 'CUSTOMER'
      },
      method: 'POST',
      body: JSON.stringify(body)
    });
    if (response.status !== 200) {
      throw Error(`Fetch error - Non-200 Status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const login = async (credentials: LoginCredentials): Promise<any> => {
  try {
    const REACT_APP_IDENTITY_URL = process.env['REACT_APP_IDENTITY_URL'];
    const response = await fetch(`${REACT_APP_IDENTITY_URL}/login-oauth`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(credentials)
    });

    if (response.status !== 200) {
      throw Error(`Fetch error - Non-200 Status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const optInTariff = async (token: string) => {
  const result = await graphQlPost(token, {
    query: updateCustomerTariffMutation,
    variables: {
      startDate: new Date().toISOString(),
      energySupplier: energySuppliers.OVO_DRIVE_ANYTIME,
      tariffType: tariffTypes.OVO_DRIVE_ANYTIME
    }
  });
  if (result.errors?.length > 0) {
    throw Error(`Mutation updateCustomerTariff failed with errors`);
  }
  return result;
};
