/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { kaluzaColors } from '@kaluza-tech/component-library';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { carBrands } from '../../constants';
import { ReactEventHandler, useState } from 'react';
import { getRedirectToLoginUrl } from './api';
import Loading from '../../components/Loading';

type Vehicle = {
  id: string;
  imageUrl: string;
  name: string;
};

type PropsVehicleCard = {
  vehicle: Vehicle;
  onClick: ReactEventHandler;
};

const D2V_CONNECTION = 'd2v';
const VehicleCard = ({ vehicle, onClick }: PropsVehicleCard) => {
  return (
    <button
      key={vehicle.id}
      onClick={onClick}
      css={css`
        margin: 5px 20px;
        width: 29%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: ${kaluzaColors.lightGrey};
        border-radius: 10px;
        border: 2px solid transparent;
        &:hover {
          border-color: ${kaluzaColors.dusk};
          cursor: pointer;
        }
        &:nth-child(3n-1) {
          margin-left: 2%;
          margin-right: 2%;
        }
        @media (max-width: 700px) {
          width: 90%;
        }
        @media (min-width: 700px) and (max-width: 1120px) {
          width: 24%;
        }
      `}
    >
      <div
        css={css`
          min-width: 30%;
          display: flex;
          justify-content: center;
        `}
      >
        <img src={vehicle.imageUrl} alt={vehicle.name} />
      </div>
      <h2
        css={css`
          color: ${kaluzaColors.dusk};
        `}
      >
        {vehicle.name}
      </h2>
    </button>
  );
};
const Vehicles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { redirect_uri, client_id, response_type } = qs.parse(window.location.search);

  const history = useHistory();

  const onVehicleClick = async () => {
    try {
      const url = await getRedirectToLoginUrl({
        redirectUrl: redirect_uri,
        clientId: client_id,
        responseType: response_type,
        connection: D2V_CONNECTION
      });
      setIsLoading(true);
      window.location = url;
    } catch (e) {
      history.push(`/error${window.location.search}`);
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div
      id="vehicles-container"
      css={css`
        padding: 0 10%;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        @media (max-width: 700px) {
          flex-direction: column;
          align-items: center;
        }
      `}
    >
      {carBrands.map((brand) => (
        <VehicleCard key={brand.id} vehicle={brand} onClick={onVehicleClick} />
      ))}
    </div>
  );
};

export default Vehicles;
