export const energySuppliers = {
  OVO_DRIVE_ANYTIME: 'OVO Energy - Drive Anytime'
};

export const tariffTypes = {
  OVO_DRIVE_ANYTIME: 'OVO_DRIVE_ANYTIME'
};

export const chargers = [
  {
    id: 'indra',
    imageUrl: '/assets/indra.png',
    models: ['Indra Smart Charge Ver 3.0¹', 'Indra Smart Pro¹'],
    connection: 'indra',
    disabled: true
  },
  {
    id: 'ohme',
    imageUrl: '/assets/ohme-home-pro.png',
    models: ['Ohme Home', 'Ohme Home Pro'],
    connection: 'ohme'
  },
  {
    id: 'hypervolt',
    imageUrl: '/assets/hypervolt.png',
    models: ['Hypervolt Home 2.0'],
    connection: 'hypervolt',
    disabled: true
  }
];

export const carBrands = [
  {
    id: 'audi',
    imageUrl: '/assets/audi.png',
    name: 'Audi'
  },
  {
    id: 'porsche',
    imageUrl: '/assets/porsche.png',
    name: 'Porsche'
  },
  {
    id: 'tesla',
    imageUrl: '/assets/tesla.png',
    name: 'Tesla'
  },
  {
    id: 'bmw',
    imageUrl: '/assets/bmw.png',
    name: 'BMW'
  },
  {
    id: 'renault',
    imageUrl: '/assets/renault.png',
    name: 'Renault'
  },
  {
    id: 'volkswagen',
    imageUrl: '/assets/volkswagen.png',
    name: 'Volkswagen'
  },
  {
    id: 'jaguar',
    imageUrl: '/assets/jaguar.png',
    name: 'Jaguar'
  },
  {
    id: 'seat',
    imageUrl: '/assets/seat.png',
    name: 'SEAT'
  },
  {
    id: 'volvo',
    imageUrl: '/assets/volvo.png',
    name: 'Volvo'
  },
  {
    id: 'mini',
    imageUrl: '/assets/mini.png',
    name: 'Mini'
  },
  {
    id: 'skoda',
    imageUrl: '/assets/skoda.png',
    name: 'Skoda'
  }
];
