/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { spacing } from '@kaluza-tech/component-library';
import { PageContainer } from '../components/layout';
import { ErrorMessage } from '../components/text';

const PageNotFound = () => (
  <PageContainer>
    <div
      css={css`
        padding: 50% ${spacing(2)}px;
        margin: 0 auto;
      `}
    >
      <ErrorMessage>Page Not Found</ErrorMessage>
    </div>
  </PageContainer>
);
export default PageNotFound;
