import qs from 'query-string';

type QueryParam = string | (string | null)[] | null;

interface ClientCredentials {
  redirectUrl: QueryParam;
  clientId: QueryParam;
  responseType: QueryParam;
  connection?: QueryParam;
}

export const checkAuth0Credentials = async (credentials: ClientCredentials) => {
  const ersAccountsApi = process.env['REACT_APP_ERS_ACCOUNTS_API'];
  const {
    clientId: client_id,
    redirectUrl: redirect_uri,
    responseType: response_type
  } = credentials;
  const creds = qs.stringify({ client_id, redirect_uri, response_type });
  const url = `${ersAccountsApi}/check-credentials?${creds}`;
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'GET'
  });

  if (response.status !== 200) {
    throw Error(response.statusText);
  }
};

export const getRedirectToLoginUrl = async (credentials: ClientCredentials): Promise<any> => {
  const ersAccountsApi = process.env['REACT_APP_ERS_ACCOUNTS_API'];
  const {
    clientId: client_id,
    redirectUrl: redirect_uri,
    responseType: response_type,
    connection
  } = credentials;
  const creds = qs.stringify({ client_id, redirect_uri, response_type, connection });
  const url = `${ersAccountsApi}/redirect-login?${creds}`;
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'GET'
  });

  if (response.status !== 200) {
    throw Error(response.statusText);
  }
  return response.json();
};
