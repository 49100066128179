/** @jsxRuntime classic */
/** @jsx jsx */
import { css, Global, jsx } from '@emotion/react';
import { colorRoles, KaluzaThemeProvider } from '@kaluza-tech/component-library';
import Routes from './Routes';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  return (
    <KaluzaThemeProvider>
      <Global
        styles={css`
          body {
            background-color: ${colorRoles.pageBackground};
            color: ${colorRoles.textPrimary};
            margin: 0;
          }
        `}
      />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </KaluzaThemeProvider>
  );
};

export default App;
