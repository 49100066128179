/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactEventHandler, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { kaluzaColors } from '@kaluza-tech/component-library';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { chargers } from '../../constants';
import { getRedirectToLoginUrl } from './api';
import Loading from '../../components/Loading';

type Charger = {
  id: string;
  imageUrl: string;
  models: string[];
  disabled?: boolean;
};

type PropsChargerCard = {
  charger: Charger;
  onClick: ReactEventHandler;
};

const ChargerCard = ({ charger, onClick }: PropsChargerCard) => {
  return (
    <button
      key={charger.id}
      onClick={onClick}
      css={css`
        display: flex;
        flex-direction: column;
        width: 200px;
        background-color: ${kaluzaColors.lightGrey};
        border-radius: 10px;
        border: 2px solid transparent;
        padding: 0 0 10px 10px;
        &:hover {
          border-color: ${kaluzaColors.dusk};
          cursor: pointer;
        }
        pointer-events: ${charger.disabled && 'none'};
        opacity: ${charger.disabled ? 0.5 : 1};
      `}
    >
      <img
        src={charger.imageUrl}
        alt={charger.id}
        css={css`
          max-height: 120px;
          max-width: 120px;
          align-self: center;
          margin: 48px;
        `}
      />
      {charger.models.map((model) => (
        <p
          css={css`
            color: ${kaluzaColors.steel};
            margin: 0;
          `}
        >
          {model}
        </p>
      ))}
    </button>
  );
};
const Chargers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { redirect_uri, client_id, response_type } = qs.parse(window.location.search);

  const history = useHistory();

  const onChargerClick = async (connection: string) => {
    try {
      const url = await getRedirectToLoginUrl({
        redirectUrl: redirect_uri,
        clientId: client_id,
        responseType: response_type,
        connection
      });
      setIsLoading(true);
      window.location = url;
    } catch (e) {
      history.push(`/error${window.location.search}`);
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        @media (min-width: 724px) {
          width: 60%;
          margin: 0 auto;
        }
      `}
    >
      <div
        id="chargers-container"
        css={css`
          background-color: white;
          display: flex;
          justify-content: space-between;
          @media (max-width: 724px) {
            flex-direction: column;
            align-items: center;
          }
        `}
      >
        {chargers.map((charger) => (
          <ChargerCard
            key={charger.id}
            charger={charger}
            onClick={() => onChargerClick(charger.connection)}
          />
        ))}
      </div>
      <p
        css={css`
          color: ${kaluzaColors.steel};
          margin-top: 20px;
          font-size: 12px;
        `}
      >
        ¹The name of the model should be featured on a sticker on the side of the charger
      </p>
    </div>
  );
};

export default Chargers;
