/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useCallback, useState, FormEvent, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  TextInput,
  spacing,
  FontWeight,
  TextInputProps
} from '@kaluza-tech/component-library';
import qs from 'query-string';
import * as api from '../api';
import { PageContainer } from '../components/layout';
import styled from '@emotion/styled';
import { InternalLink, ErrorMessage } from '../components/text';

const SHOW_ERROR_MESSAGE_TIME = 5000;

const InputContainer = styled.div`
  margin: ${spacing(2, 0)};
`;

const isNonEmptyString = (x: unknown) => typeof x === 'string' && x !== '';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);

  const parsedQs = qs.parse(window.location.search);
  const { redirect_uri, client_id } = parsedQs;
  const history = useHistory();

  useLayoutEffect(() => {
    setIsLoading(true);
    api
      .checkCredentials({ redirectUrl: redirect_uri, clientId: client_id })
      .catch(() => {
        history.push(`/error${window.location.search}`);
      })
      .finally(() => setIsLoading(false));
  }, [redirect_uri, client_id, history]);

  const handleLogin = useCallback(
    async (username, password) => {
      if (!isNonEmptyString(redirect_uri) || !isNonEmptyString(client_id)) {
        return;
      }

      const emailRegex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const validEmail = emailRegex.test(username);
      if (!validEmail) {
        return setErrorMessage(
          "The email you entered doesn't look right. Please check and try again."
        );
      }

      setIsLoading(true);
      const loginResult = await api.login({
        username,
        password,
        redirectUrl: redirect_uri,
        clientId: client_id
      });
      if (loginResult.success) {
        const { redirectUrlFinal, idToken } = loginResult;
        try {
          await api.optInTariff(idToken);
          window.location = redirectUrlFinal;
        } catch (error) {
          setErrorMessage('Something has gone wrong. Please email us on smarthome@ovoenergy.com.');
          setIsLoading(false);
        }
      } else {
        setErrorMessage("Your email or password wasn't recognised. Please check and try again.");
        setIsLoading(false);
      }
    },
    [redirect_uri, client_id]
  );

  const isFormValid = () => {
    const isEmptyUsername = username.trim() === '';
    const isEmptyPassword = password.trim() === '';
    if (isEmptyUsername || isEmptyPassword) {
      if (isEmptyUsername) {
        setIsEmailError(true);
        setTimeout(() => {
          setIsEmailError(false);
        }, SHOW_ERROR_MESSAGE_TIME);
      }
      if (isEmptyPassword) {
        setIsPasswordError(true);
        setTimeout(() => {
          setIsPasswordError(false);
        }, SHOW_ERROR_MESSAGE_TIME);
      }
      return false;
    }
    return true;
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }

    handleLogin(username, password);
  };

  const formDisabled = isLoading;

  const commonTextInputProps: TextInputProps = {
    disabled: formDisabled,
    fullWidth: true,
    variant: 'outlined'
  };

  return (
    <PageContainer>
      <div
        css={css`
          padding: ${spacing(4, 2)};
          text-align: center;
          font-weight: ${FontWeight.Medium};
        `}
      >
        <img
          css={css`
            width: 80%;
            max-width: 350px;
            margin-bottom: ${spacing(2)}px;
          `}
          src="/assets/logo-light-horiz.png"
          alt="Kaluza logo"
        />
        <form onSubmit={handleSubmit}>
          <div
            css={css`
              margin: ${spacing(2)}px ${spacing(2)}px ${spacing(4)}px;
              text-align: center;
            `}
          >
            Enter the details you registered with your EV charger
          </div>
          <InputContainer>
            <TextInput
              name="email"
              error={isEmailError}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              label="Email"
              {...commonTextInputProps}
            />
          </InputContainer>

          <InputContainer>
            <TextInput
              name="password"
              error={isPasswordError}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type="password"
              label="Password"
              {...commonTextInputProps}
            />
          </InputContainer>

          {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

          <div
            css={css`
              margin: ${spacing(4, 0)};
            `}
          >
            <Button disabled={formDisabled} type="submit" variant="contained">
              Connect your charger
            </Button>
          </div>

          <InternalLink to={{ pathname: '/help', search: window.location.search }}>
            Having trouble connecting your charger?
          </InternalLink>
        </form>
      </div>
    </PageContainer>
  );
};

export default LoginPage;
