import styled from '@emotion/styled';
import { colorRoles, FontWeight, SizeVariation, spacing } from '@kaluza-tech/component-library';
import { Link } from 'react-router-dom';

export const PageTitle = styled.h1`
  text-transform: uppercase;
  font-size: ${SizeVariation.x04};
  color: ${colorRoles.textSecondary};
  font-weight: ${FontWeight.Regular};
  margin: 0;
`;

export const SubTitle = styled.h2`
  font-size: ${SizeVariation.x09};
  margin: ${spacing(4, 0, 1)};
`;

const linkStyles = `
  color: ${colorRoles.primary};
  text-decoration: none;
  font-weight: ${FontWeight.Medium};
`;

export const ExternalLink = styled.a`
  ${linkStyles}
`;

export const InternalLink = styled(Link)`
  ${linkStyles}
`;

export const Text = styled.p`
  font-size: ${SizeVariation.x05};
  margin: 0;
`;

export const ErrorMessage = styled.div`
  background-color: ${colorRoles.error};
  color: ${colorRoles.textDark};
  padding: ${spacing(1)}px;
  margin: ${spacing(3, 0)};
  font-size: ${SizeVariation.x04};
  font-weight: ${FontWeight.Bold};
`;
